import React from 'react';

const InfoBlock = () => {
  return (
    <div className="container mt-4">
      <hr/>
      <h2>Получите Мгновенную Расшифровку Анализа Крови с Помощью Искусственного Интеллекта!</h2>
      <p>
      Наш инновационный онлайн сервис основан на передовых алгоритмах и технологии искусственного интеллекта. 
      Теперь вы можете мгновенно получить интерпретацию результатов вашего анализа крови с выдачей рекомендаций, 
      опираясь на точные данные и анализ, предоставленный нашим программным обеспечением.
      </p>
      <p><strong>Преимущества Использования Нашего Сервиса:</strong></p>
      <ul>
        <ol><strong>Высокая Точность Алгоритмов:</strong> Наши алгоритмы работают с высокой точностью и внимательно анализируют каждый показатель, чтобы предоставить вам точную интерпретацию.</ol>
        <ol><strong>Быстрый Инсайт:</strong> Забудьте о долгом ожидании результатов. Наш сервис позволяет получить мгновенное понимание ваших показателей анализа крови.</ol>
        <ol><strong>Персонализированные Рекомендации:</strong> Наше программное обеспечение не только анализирует ваши данные, но и предоставляет индивидуальные рекомендации, учитывая вашу ситуацию.</ol>
        <ol><strong>Предварительная Подготовка к Визиту к Специалисту:</strong> Наши рекомендации помогут вам подготовиться к более продуктивному и информированному обсуждению вашего состояния с врачом.</ol>
        <ol><strong>Осведомленное Принятие Решений:</strong> Наш сервис предоставляет вам знания, которые помогут вам осознанно принимать решения о вашем здоровье.</ol>
      </ul>
      <p>
      Однако, несмотря на точность нашего программного обеспечения, помните, 
      что только человек способен оценить полный контекст. 
      Важно всегда обращаться к квалифицированным врачам для уточнения диагноза, 
      особенно если у вас есть какие-либо беспокойства. Алгоритмы могут быть точными, 
      но врач обладает непередаваемым человеческим опытом и интуицией. 
      <br/><strong> Мы рекомендуем использовать оба подхода для более глубокого понимания вашего здоровья.</strong>
      </p>
    </div>
  );
};

export default InfoBlock;